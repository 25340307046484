.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-image-preview-operations-operation-flipY,
.ant-image-preview-operations-operation-flipX,
.ant-image-preview-operations-operation-rotateLeft,
.ant-image-preview-operations-operation-rotateRight  {
  display: none !important;
}

.ant-image-preview-operations-operation-zoomOut {
  margin-inline-start: 0;
}

.ant-image-preview-operations-operation>.anticon {
  font-size: 25px;
}

.ant-image-preview-progress {
  font-size: 25px;
}

.ant-image-preview-switch-right,
.ant-image-preview-switch-right:hover,
.ant-image-preview-switch-left,
.ant-image-preview-switch-left:hover {
  width: 5vh;
  height: 5vh;
}

.ant-image-preview-switch-right:hover,
.ant-image-preview-switch-left:hover {
background: hsla(0, 0%, 17%, 0.65);
color: rgba(255, 255, 255, 0.65);
}

.ant-image-preview-switch-right,
.ant-image-preview-switch-left {
background: hsla(0, 0%, 0%, 0.65);
color: rgba(255, 255, 255, 0.65);
}

.ant-image-mask-info {
  display: flex;
  font-size: 0; /* This will hide the original text */
}

.ant-image-mask-info > span {
  font-size: 14px; /* Reset the font size for the span */
}

.ant-image-mask-info::after {
  content: "Zobrazit";
  font-size: 14px; /* Reset the font size for the new text */
  display: block;
}

.car-widget {
  max-height: 30em;
  aspect-ratio: 1;
}

.car-detail-modal {
  top: 1em;
}

.car-price {
  color: #bd302d;
  padding-left: 5px;
}

.cars-row {
  margin-top: 10px;
  padding-top: 1em;
  padding-bottom: 1em;
  column-gap: 1em;
  row-gap: 1em;
  justify-content: center;
  border-radius: 10px;
}

.big-page {
  padding-top: 15px;
  background-color: #f5f5f586;
  border-radius: 10px;
}

.image-wrapper {
  padding-top: 10px;
}

.image-wrapper > .ant-image {
    width: 30% !important;
  }

.image-wrapper .more-images {
  width: 30% !important;
}

.images-col > .ant-image {
  /* height: 75%; */
  position: relative;
  > img {
    height: 100%; /* Make the image as tall as the wrapper */
    object-fit: contain; /* Keep the aspect ratio while fitting within the container */
    width: auto; /* Allow the width to adjust based on the height and aspect ratio */
    max-width: 100%;
    max-height: 100%;

  }
}

.ant-image-preview-img {
  max-height: 90vh !important;
}

.main-image > .ant-image {
  display: flex;
  justify-content: end;
}