body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-card {
  height: 30em;
}

.custom-card-img {
  height: 10rem;
  /* width: 10em; */
  object-fit: cover;
}

.service-name {
  font-size: 24px;
  font-weight: 600;
}

.service-name-icon svg {
  font-size: 70px;
  padding-bottom: 10px;
}

.service-col {
  transition: all 0.3s ease;
  border-radius: 10px;
  border: 1px solid #dadada;
}

.service-col:hover {
  box-shadow: 5px 5px 15px rgba(0,0,0,0.3);
  transform: translateY(-5px);
}
.page {
  padding: 0 20px;
  .page-name {
    text-align: center;
  }
  .page-description {
    text-align: center;
    padding-bottom: 20px;
    font-size: 19px;
    font-weight: 400;
  }
  .about-us {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .about-us-text {
    display: inline-block;
    padding-right: 15px;
  }

  .about-us-paragraph {
    font-weight: 200;
  }
}

.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 15px;
  }
  h1 {
    /* font-size: 30px; */
  }
  h2 {
    font-size: 23px;
    margin-bottom: 0px;
  }
}

.app-header {
  background-color: #001529;
  color: white;
}

.app-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #001529;
  color: white;
}